(<template>
  <page-data-section :title="sectionTitle"
                     :progress="isProgressActive"
                     class="comp-security">
    <div class="comp-security__logos-cont">
      <div v-for="logo in securityLogos"
           :key="logo"
           class="comp-security__logo"
           :class="[`comp-security__logo--${logo}`]"></div>
    </div>
    <div class="comp-security__content-wrapper">
      <div class="comp-security__sections-wrapper">
        <info-section v-for="section in sectionsArr"
                      :key="section.title"
                      :hide-description="false"
                      :section-data="section" />
      </div>
    </div>
    <div v-if="showEditBtn"
         class="comp-security__btn-cont">
      <router-link :to="$makeRoute({name: 'BuyerSecurityEdit'})"
                   class="sk-btn sk-btn--default comp-security__btn">{{ $gettext('Edit') }}</router-link>
    </div>
  </page-data-section>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import InfoSection from '@/components/enterprise/comp_info/InfoSection';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';

  export default {
    asyncData({store}) {
      const myEntId = store.getters['UserInfoStore/enterpriseId'];
      let promiseArr = '';
      if (!store.state.TTEnterpriseSecurityStore.securityInfo) {
        promiseArr = [...promiseArr, store.dispatch('TTEnterpriseSecurityStore/getEnterpriseSecurity', myEntId)];
      }

      return promiseArr ? Promise.all(promiseArr) : promiseArr;
    },
    components: {
      'page-data-section': PageDataSection,
      'info-section': InfoSection
    },
    data() {
      return {
        isProgressActive: false,
        providerMapping: {
          'sts.windows.net': 'Azure AD',
        }
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid', 'userIsAdminOfEnterprise']),
      ...mapState('TTEnterpriseSecurityStore', {
        provider: (state) => (state.securityInfo && state.securityInfo.saml && state.securityInfo.saml.idpMetadata && state.securityInfo.saml.idpMetadata.provider ? state.securityInfo.saml.idpMetadata.provider : ''),
      }),
      showEditBtn() { return this.userIsAdminOfEnterprise || this.userIsOwnerOfEnterprise || this.$isGodmode(); },
      userIsOwnerOfEnterprise() { return this.userUid == this.enterpriseOwnerUid; },
      sectionTitle() { return this.$gettext('SAML Configuration'); },
      sectionsArr() {
        return [
          this.sectionProvider,
        ];
      },
      sectionProvider() {
        return this.provider ? {
          title: `<b>${this.$gettext('SAML Provider:')}</b>`,
          data: [this.providerMapping[this.provider] || this.provider],
        } : {};
      },
      securityLogos() {
        if (!this.provider) {
          return [];
        }
        if (this.provider === 'sts.windows.net' || this.provider === 'Azure AD') {
          return ['microsoft', 'boxyhq'];
        }
        return ['saml', 'boxyhq'];
      }
    }
  };
</script>

<style>
.comp-security__sections-wrapper .info-section__title {
  font-size: 13px;
}

.comp-security__sections-wrapper .info-section__text {
  font-size: 15px;
}
</style>

<style scoped>
  .comp-security__content-wrapper {
    display: flex;
    flex-grow: 1;
    margin: 25px 0;
    padding: 10px 5px;
    border-radius: 4px;
    background-color: #f4f5f7;
  }

  .comp-security__sections-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 380px;
  }

  .comp-security__btn-cont {
    display: flex;
  }

  .comp-security__btn {
    width: auto;
    padding: 0 20px;
  }

  @media (max-width: 600px) {
    .comp-security__content-wrapper {
      flex-direction: column-reverse;
    }

    .comp-security__sections-wrapper {
      width: 100%;
      max-width: initial;
      padding: 0;
    }
  }

  .comp-security__logos-cont {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .comp-security__logo {
    display: block;
    width: 150px;
    height: 50px;
    background-position: left;
    background-size: auto 50px;
    background-repeat: no-repeat;
  }

  .comp-security__logo--saml {
    width: 180px;
    background-image: url(~@assets/imgs/logos/saml_logo.png);
    background-size: auto 100px;
  }

  .comp-security__logo--microsoft {
    background-image: url(~@assets/imgs/logos/microsoft_logo.png);
    background-size: auto 50%;
  }

  .comp-security__logo--boxyhq {
    background-image: url(~@assets/imgs/logos/boxyhq_logo.png);
  }
</style>

(<template>
  <section v-if="sectionData"
           :class="{'info-section--with-edit': canEdit}"
           class="info-section">
    <h2 v-if="!hideDescription"
        class="info-section__title"
        v-html="title"></h2>
    <p v-for="(text, index) in info"
       :key="index"
       class="info-section__text"
       v-html="text"></p>
    <button v-if="canEdit"
            class="edit-pencil-btn comp-info__edit-pencil-btn--section"
            @click="openEditModal"></button>
  </section>
</template>)

<script>
  export default {
    props: {
      sectionData: {
        type: Object,
        default() {
          return {};
        }
      },
      canEdit: Boolean,
      hideDescription: Boolean
    },
    computed: {
      title() {
        return this.sectionData.title;
      },
      info() {
        return this.sectionData.data;
      }
    },
    methods: {
      openEditModal() {
        this.$emit('openmodal');
      }
    }
  };
</script>

<style scoped>
  .info-section {
    display: block;
    width: 100%;
    padding: 0;
  }

  .info-section--with-edit {
    position: relative;
    display: inline-block;
    width: auto;
    padding-right: 35px;
  }

  .info-section__title {
    display: block;
    width: 100%;
    margin: 0;
    font-weight: normal;
    font-size: 10px;
  }

  .info-section__text {
    display: block;
    overflow: hidden;
    width: 100%;
    margin-bottom: 0;
    text-overflow: ellipsis;
  }
</style>

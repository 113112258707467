(<template>
  <section :class="{'in-progress': progress}"
           class="page-data-section is-progress-bar">
    <div v-if="errors && errors.length"
         class="error-cont page-data-section__errors-cont">
      <p v-for="error in errors"
         :key="error"
         class="error-cont__error"
         v-html="error"></p>
    </div>
    <div v-if="title || description"
         class="page-data-section__heading">
      <h3 v-if="title"
          class="page-data-section__title"
          v-html="title"></h3>
      <div v-if="description"
           class="page-data-section__description"
           v-html="description"></div>
    </div>
    <slot></slot>
  </section>
</template>)

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      errors: {
        type: Array,
        default: () => []
      },
      progress: Boolean
    }
  };
</script>
